<template>
  <div class="login">
     <AliVertify></AliVertify>
    <div class="header">
      <div class="headIcon" @click.stop="toHome"></div>
    </div>
    <div class="content">
      <div class="loginBox" v-if="toSignIn==false">
        <p class="boxName">登录河小象</p>
        <p class="boxDes">仅限VIP学员登录，<span class="turnVip" @click.stop="toVip">如何成为VIP？</span></p>
        <div class="formItem mgb0">
          <div class="icon"><div class="telIcon"></div></div>
          <input type="text" v-model.trim="cellphone" class="cellphone" placeholder="请输入登录手机号" @change="verify" @focus="setCellphoneActive">
        </div>
        <p class="errHint">{{verifyPhone}}</p>
        <div v-show='nowShow'>
        <div class="formItem">
          <div class="icon"><div class="codeIcon"></div></div>
          <input type="text" v-model.trim="code" v-on:keyup.enter="login" class="code" placeholder="请输入验证码">

          <button class="getCode" @click.stop="getCode" v-if="isGetCode == false" :class="cellphone != null && cellphone != '' ? 'canClick': ''">{{codeText}}</button>
          <button class="getCode isGetCode"  v-if="isGetCode == true">{{timeNum}} s</button>
        </div>
        <div id="vertify" v-show="checkShow"></div>
        </div>
        <div class="formItem ngb0" v-if="printCode == false">
          <div class="icon"><div class="codeIcon"></div></div>
          <input type="password" v-model.trim="password"  v-on:keyup.enter="login" class="password" placeholder="请输入登录密码">
        </div>
        
        <p class="errHint mgb0">{{errMsg}}</p>
        <p class="passwordItem">
          <span class="passwordLogin" @click="turnPassword">{{turnText}}</span>
          <span class="turnPassword" @click="turnPassword"></span>
        </p>
        <button class="loginBtn" @click.stop="login">登录学习</button>
        <button class="signinBtn" @click.stop="signin">微信扫码注册</button>
      </div>
      <div class="weChatSignin" v-if="toSignIn==true">
        <img :src="weChatSignin" class="weChatSigninImg">
        <p class="weChatSigninDes">打开微信扫一扫</p>
        <button class="returnTel" @click="returnTel">返回手机登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import AliVertify from '../components/AliVertify'
export default {
  name: "login",
  data() {
    return {
      ic:{},
      nowShow: true, //默认展示验证码登录
      checkShow:false, //人机验证按钮
      token:'',
      sig:'',
      sessionId:'',
      vertifyShow:false,//判断是否验证通过
      printCode: true, //判断是否输入验证码
      turnText: "密码登录",
      timeNum: 60,
      codeText: '获取验证码',
      isGetCode: false, //判断是否点击获取验证码
      cellphone: null,
      code: null,
      password: null,
      toSignIn: false,
      verifyPhone: '',
      errMsg: '',
      bgImg: 'https://xcx.alicdn2.hexiaoxiang.com/web_static/loginBackground.png',
      weChatSignin: 'https://xcx.alicdn2.hexiaoxiang.com/common/mini_app_sign_in_qr.png' //微信扫码注册二维码
    };
  },
  components:{
    AliVertify:{
      render(createElement){
        const self=this
        return createElement('script',{attrs:{type:'text/javascript',src:'https://g.alicdn.com/sd/nvc/1.1.112/guide.js'}})
      }
    },
  },
  mounted() {
    this.cellphone = localStorage.getItem("user");
    this.password = localStorage.getItem("password");
    this.getGobalConfig()

  },
  methods: {
    getGobalConfig(){
       window.NVC_Opt = {
        //应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
        appkey:'FFFF0N000000000088AE',
        //使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
        scene:'ic_message_h5',
        //声明二次验证需要渲染的目标元素ID。
        renderTo:'#captcha',
        //业务键字段，可为空。该参数可用于上线前测试，请按照代码集成后测试部分中的方法配置该字段值。
        trans: {"key1": "code0","nvcCode":200},
        //当唤醒刮刮卡验证作为二次验证时，配置需要刮出的两个elements的图片资源。
        elements: [
            'https://img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png',
            'https://img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png'
        ], 
        //当唤醒刮刮卡验证作为二次验证时，配置刮动时的背景图像（自动应用平铺填充的方式）。
        bg_back_prepared: '//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png',
        //当唤醒刮刮卡验证作为二次验证时，配置刮动时的前景图像（仅支持base64数据流）。
        bg_front: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC',
        //当唤醒刮刮卡验证作为二次验证时，配置验证通过时显示的图标资源。
        obj_ok: '//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png',
        //当唤醒刮刮卡验证作为二次验证时，配置验证通过时显示的背景图像（自动应用平铺填充的方式）。
        bg_back_pass: '//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png',
        //当唤醒刮刮卡验证作为二次验证时，配置验证失败或异常时显示的图标资源。
        obj_error: '//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png',
        //当唤醒刮刮卡验证作为二次验证时，配置验证失败或异常时显示的背景图像（自动应用平铺填充的方式）。
        bg_back_fail: '//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png',
        //当唤醒刮刮卡验证作为二次验证时，用于自定义文案。详细配置方法请参见自定义文案文档。
        upLang:{"cn":{
            _ggk_guide: "请摁住鼠标左键，刮出两面盾牌",
            _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
            _ggk_loading: "加载中",
            _ggk_fail: ['呀，盾牌不见了<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
            _ggk_action_timeout: ['我等得太久啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
            _ggk_net_err: ['网络实在不给力<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
            _ggk_too_fast: ['您刮得太快啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题']
            }
        }
    }
    this.initMessage()

    },
    initMessage(){
       let that=this
         var ic = new smartCaptcha({
              //声明智能验证需要渲染的目标元素ID。
              renderTo: '#vertify',
              //智能验证组件的宽度。
              width: 370,
              //智能验证组件的高度。
              height: 42,
              //智能验证组件初始状态文案。
              default_txt: '点击按钮开始智能验证',
              //智能验证组件验证通过状态文案。
              success_txt: '验证成功',
              //智能验证组件验证失败（拦截）状态文案。
              fail_txt: '验证失败，请在此点击按钮刷新',
              //智能验证组件验证中状态文案。
              scaning_txt: '智能检测中',
              //前端智能验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
              success: function(data) {
                // console.log(NVC_Opt.token);
                // console.log(data.sessionId);
                // console.log(data.sig); 
                that.token=NVC_Opt.token
                that.sessionId=data.sessionId
                that.sig=data.sig              
                that.vertifyShow=true
                that.isGetCode = true;
                that.messageVertify(that.sessionId,that.sig,that.token)
              },
              fail: function() {
                console.log('ic error');
              }
        });
        ic.init();
        this.ic=ic
    },
    //跳转成为vip
    toVip() {
      window.open('https://detail.youzan.com/show/goods?alias=367s8ezpzy79g');
    },
    //填手机号获取焦点
    setCellphoneActive() {
      console.log(1)
    },
    //返回首页
    toHome() {
      this.$router.push({ path: "/" });
    },
    //验证手机
    verify() {
      if (this.cellphone == null && this.cellphone == '') {
        this.verifyPhone = '请输入手机号';
      } else {
        if(!(/^1\d{10}$/.test(this.cellphone))){
          this.verifyPhone = '手机号格式错误，请重新输入';
        } else {
          this.verifyPhone = '';
        }
      }
    },
    //登录学习
    login() {
      let that = this;
      let obj = {};
      if (this.cellphone == null) {
        this.verifyPhone = '请输入手机号';
        return;
      }
      if (this.printCode == true && this.code == null) {
        this.errMsg = '请输入验证码';
        return;
      }
      if (this.printCode == false && this.password == null) {
        this.errMsg = '请输入密码';
        return;
      }
      obj.cellphone = this.cellphone;
      localStorage.setItem("user", this.cellphone);
      if (this.printCode == true) {
        obj.method = "code";
        obj.code = this.code;
      } else {
        obj.method = "password";
        obj.password = this.password;
        localStorage.setItem("password", this.password);
      }
      this.axios
        .post("/api/login", obj)
        .then(res => {
          if (res.data.code == 20000) {
            if (localStorage.getItem("key") != res.data.data.key) {
               localStorage.clear();
            }
            localStorage.setItem("key", res.data.data.key);
            localStorage.setItem("avatarUrl", res.data.data.user.avatarUrl ? res.data.data.user.avatarUrl : ""); //头像
            localStorage.setItem("childNn", res.data.data.user.childNn); //昵称
            localStorage.setItem("vipType", res.data.data.user.vipType); //是否VIP 1不是2是
            localStorage.setItem("number", res.data.data.user.number); //学号
            localStorage.setItem(
              "expireTime",
              res.data.data.user.expire_time
            ); //过期时间
            setTimeout(() => {
              that.$router.push({ path: "/classroom" });
            }, 500);
          } else {
            that.errMsg =  res.data.errMsg;

          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //切换登录方式
    turnPassword() {
      this.printCode = !this.printCode;
      if (this.printCode == false) {
        this.turnText = "验证码登录";
        this.checkShow = true
        this.nowShow = false
      } else {
        this.turnText = "密码登录";
         this.checkShow = false
         this.nowShow = true
      }
    },
    //显示微信扫码注册
    signin() {
      this.toSignIn = true;
    },
    //返回手机号登录
    returnTel() {
      this.toSignIn = false;
    },
    //获取验证码
    getCode() {
      let that = this;
      let obj = {};
      if (this.cellphone == null) {
        this.verifyPhone = '请输入手机号';
        return;
      }  else if(!(/^1\d{10}$/.test(this.cellphone))){
        this.verifyPhone = '手机号格式错误';
        return;
      }
      this.checkShow=true
      this.ic.reset()     
    },
    messageVertify(session_id,sig,token){
      this.showMask=false
      let that=this
       let codeTime = setInterval(function() {
        if (that.timeNum == 1) {
          that.isGetCode = false;
          that.codeText = '重新获取';
          that.timeNum = 60;
          clearInterval(codeTime);
          return;
        }
        that.timeNum = that.timeNum - 1;
      }, 1000);
      let obj={
        cellphone:this.cellphone,
        session_id:session_id,
        sig:sig,
        token:token,
        scene:'ic_message_h5'
      }
      this.axios
        .post("https://api.hexiaoxiang.com/cerberus/turingtest", obj)
        .then(res => {
          if (res.data.code == 20000) {
            alert("已发送");
          } else {
            alert(res.data.msg);
            location.reload() 
            
          }
        })
        .catch(error => {
          console.log(error);
        });

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.canClick {
  background-color: rgba(43, 141, 247) !important;
}
.canClick:hover {
  background-color: rgba(43, 141, 247, 0.8) !important;
}
.login {
  width: 100vw;
  height: 100vh;
  background-color: #98c3e6;
  background-image: url(https://xcx.alicdn2.hexiaoxiang.com/web_static/loginBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 1200px;
  overflow: hidden;
}
.colorCCC {
  color: #CCC;
}
.mgb0 {
  margin-bottom: 0 !important;
}
.errHint {
  margin-bottom: 16px;
  height: 16px;
  font-size: 12px;
  color: #FF5159;
  text-align: left;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background-color: rgba(49,49,49,0.1);
  box-shadow:0px 2px 10px 0px rgba(0,0,0,0.26);
}
.content {
  position: fixed;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 490px;
}
.loginBox {
  padding: 24px 30px 40px;
  box-sizing: border-box;
  width: 430px;
  min-height: 490px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  border-radius: 4px;
}
.bgImg {
  width: 498px;
  height: 462px;
}
.weChatSignin {
  position: relative;
  box-sizing: border-box;
  width: 430px;
  height: 468px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow:0px 4px 8px 0px rgba(0,0,0,0.2);
}
.weChatSigninImg {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 240px;
  height: 240px;
}
.returnTel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 430px;
  height: 68px;
  background-color: #5dc0fa;
  border-radius: 0 0 16px 16px;
  color: #fff;
  font-size: 22px;
  border: none;
  outline: none;
  cursor: pointer;
}
.boxName {
  margin-bottom: 8px;
  height: 20xp;
  font-size: 20px;
  color: #0d141e;
  text-align: left;
  font-weight: bold;
}
.boxDes {
  margin-bottom: 40px;
  height: 16px;
  font-size: 16px;
  color: #5d646e;
  text-align: left;
  font-weight: bold;
}
.turnVip {
  color: #2b8df7;
  cursor: pointer;
}
.formItem {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 370px;
  height: 50px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
}
.passwordItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  width: 370px;
  height: 20px;
  line-height: 20px;
  text-align: right;
  color: #0d141e;
  font-size: 16px;
  font-weight: bold;
}
.icon {
  flex: 0 0 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 30px;
  border-right: 1px solid rgba(93, 100, 110, 0.2);
}
.telIcon {
  width: 14px;
  height: 22px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAsBAMAAACEWcUKAAAAGFBMVEUAAABcZG5dZG5dZW5dY25eZW5bZG1dZG7h4FUdAAAAB3RSTlMAf/LQyG0c4F5htQAAAFFJREFUKM9jYGB1KocClQAGBjbzcjgoTmBgLkcCBgzhyNxSBndkbgmDOjK3iKG8kAEOxMtHuYOSiwxI5KqDSSCARLc7MrcELamgJSS0ZIaWCAGHyoTRNDU38wAAAABJRU5ErkJggg==");
  background-size: 14px 22px;
}
.codeIcon {
  width: 17px;
  height: 22px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAsCAMAAAA3k5qAAAAAOVBMVEUAAABdZG5dZW9eZG5aYG5dZG5dZG5fX2xeZHJdZG5cY25cY25dZW5eZG9dZW9dZG5AQIAAAP9dZG6IuCEjAAAAEnRSTlMA863FJZntGBO3uZWJemVKBAGJBH7GAAAAmklEQVQ4y9XU3QrCMAyG4SXZ1tb9f/d/sbLRiYXEKEVx70Gh8ECP+jW5lglKxO1D9DDqT8Mw40zIJpQJXvQZaZR+TnK1ZJ2FSObVJlFwJNEimwAyTfu5GWQBhv06AItBRiAe7wGjQTrgvHdfJOGGp1JQSCEAVgjKyCe4PiGfcCmSQkIqtiLU/oB/+bA+qV8pfw79UX1nmv2BvwPpOTDvBkGlZQAAAABJRU5ErkJggg==");
  background-size: 17px 22px;
}
.cellphone,
.password {
  flex: 1;
  padding-left: 16px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 16px;
}
.code {
  flex: 1;
  padding-left: 16px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 16px;
}
.getCode {
  margin-right: 12px;
  flex: 0 0 90px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 12px;
  color: #fff;
  background-color: rgba(141, 148, 153, 1);
  border-radius: 15px;
  cursor: pointer;
}
.isGetCode {
  flex: 0 0 60px;
}
.getCode:hover {
  background-color: rgba(141, 148, 153, 0.6);
}
.passwordLogin {
  cursor: pointer;
}
.turnPassword {
  margin-left: 5px;
  width: 14px;
  height: 14px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAS1BMVEUAAAAMFR0NFB0NFB4NFB4NEx4NFB4NFB4MEh8PFx8AAAANFB7///9ydnwlLDX4+Pjq6+zd3t/DxceusLOKjZI8QUkaISqjpalPVFxYTNDqAAAAC3RSTlMAPuvn2MbCiVMhAR8vYCcAAACRSURBVCjPfZNXDsMwDEPleJtuusf9T1qgQ5CCgO/PIERo0PJnhFoiEEsNQza0BCU1J/UMR+6ihAUblqAadvipXetc7dc5Y5f86VOfzwMsTWToDOtpXh9GTMN2s17m+e56qjDc5tFYVymwvKx1kQiDt44Ch7emIrWlDdFR6BLo+tji2cnosVlMeMB4NHmo6Xd4A43rG3XGdanMAAAAAElFTkSuQmCC");
  background-size: 14px 14px;
  cursor: pointer;
}
.loginBtn {
  margin-bottom: 24px;
  width: 370px;
  height: 48px;
  background-color: rgba(43, 141, 247, 1);
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
}
.loginBtn:hover {
  background-color: rgba(43, 141, 247, 0.8);
}
.signinBtn {
  width: 370px;
  height: 48px;
  font-size: 18px;
  background-color: #fff;
  color: rgba(67, 201, 167, 1);
  border: 1px solid rgba(67, 201, 167, 1);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}
.signinBtn:hover {
  background-color: #ecf5ff;
}
.headIcon {
  position: absolute;
  top: 20px;
  left: 67px;
  width: 140px;
  height: 34px;
  background-image: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/logo_m.png");
  background-size: 140px 34px;
  cursor: pointer;
}
.weChatSigninDes {
  position: absolute;
  top: 315px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 0.3rem;
  line-height:0.3rem;
  font-size: 0.23rem;
  color: #0D141E;
}
#vertify{
  margin-bottom: 30px;
}
</style>

<style>
#vertify .sm-btn{
  border: 1px solid rgba(67, 201, 167, 1)
}

#vertify .sm-txt{
  font-size: 18px;
    background-color: #fff;
    color: rgba(67, 201, 167, 1);
    border-radius: 4px; 
}
.sm-pop{
  left: 0 !important;
  width: 100% !important;
}
</style>